<template>
    <BaseSection
        class="flexible-sections__section learning-paths"
        :class="`learning-paths--${sliderType}`"
    >
        <template
            v-if="sliderType === 'summary'"
            #title
        >
            <h3>Summary</h3>
        </template>
        <BaseSlider
            v-if="items.length"
            :items="items"
            :controls-enabled="true"
            class="learning-paths__wrapper"
        >
            <template v-slot:slide="{ item }">
                <BaseCardLearningPath
                    :key="item.uri"
                    :item="item"
                    :uri="item.uri"
                    :hide-header="!items.filter((i) => i.icon)?.length"
                    :slider-type="sliderType"
                >
                    <template #header>
                        <BaseIcon
                            :icon="item.icon"
                            :auto-color="false"
                        />
                    </template>
                    <template #title>{{ item.title }}</template>
                    <template #default>{{ item.text }}</template>
                </BaseCardLearningPath>
            </template>
        </BaseSlider>
        <div
            v-if="items.length"
            class="learning-paths--grid"
        >
            <BaseCardLearningPath
                v-for="item in items"
                :key="item.uri"
                :item="item"
                :uri="item.uri"
                :hide-header="!items.filter((i) => i.icon)?.length"
                :slider-type="sliderType"
            >
                <template #default>{{ item.text }}</template>
            </BaseCardLearningPath>
        </div>
    </BaseSection>
</template>

<script setup>
const { mapCmsIcon } = useCmsIcon();
const { uriFromEntryFields } = useUri();

const props = defineProps({
    learningPaths: {
        type: Array,
        required: true,
        default: () => []
    },
    sliderType: {
        type: String,
        required: false,
        default: 'slide'
    }
});

const items = computed(() => {
    return props.learningPaths.map((learningPath) => {
        return {
            title: learningPath.title,
            text: learningPath.text,
            icon: mapCmsIcon(learningPath.icon),
            uri: uriFromEntryFields(learningPath)
        };
    });
});
</script>

<style lang="less">
.flexible-sections *.learning-paths {
    grid-column: -1/1 !important; /* stylelint-disable-line declaration-no-important */ // bleed
    position: relative;
    margin-top: var(--spacing-section);
}

.learning-paths {
    @media @q-lg-max {
        .grid-container;
    }
}

.learning-paths--grid {
    display: none;
    grid-template-columns: repeat(auto-fill, minmax( calc(calc(var(--grid-maxWidth-content) / 2) - var(--spacing-md)), 1fr));
    gap: var(--spacing-md);
}

.learning-paths__wrapper {
    display: flex;
    max-width: calc(100vw - 3rem);
    overflow: visible;
    --slider-gap: var(--spacing-xs);

    @media @q-md-min {
        --slider-gap: var(--spacing-md);
    }
}

.learning-paths__control {
    position: absolute;
    z-index: 100;
    top: -3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.25rem;
    height: 2rem;
    font-size: 1rem;
    border: 0;
    background: var(--color-secondary-500, var(--color-secondary));
    opacity: .45;
    transition: opacity var(--transition-fast);
    color: var(--color-darkest);
    border-radius: var(--borderRadius-full);
    cursor: pointer;

    &:hover,
    &:focus {
        background: var(--color-secondary-500, var(--color-secondary));
        opacity: 1;
    }
}

.slider__control-left,
.slider__control-right {
    grid-area: none;

    [data-left] {
        right: 3.75rem;
        transition: right var(--transition-fast);
    }

    [data-right] {
        right: 0;
    }
}

.slider__control-left:not(:has(~ .slider__control-right)) [data-left] {
    right: 0;
}

// TODO: maybe refactor to two separate components
// 1. The version used on the homepage / in the flexible sections
// 2. The version used on the condition detail page
.learning-paths--summary {
    .learning-paths--grid {
        display: none;

        @media @q-lg-min {
            display: grid;
        }
    }

    .learning-paths__wrapper {
        display: flex;
        
        @media @q-lg-min {
            display: none;
        }
    }
}
</style>
