<template>
    <NuxtLink
        :to="url"
        :target="link.target"
    >
        <slot>{{ link.text }}</slot>
    </NuxtLink>
</template>

<script setup>
import { HOME_URI } from '~/enums/page-uri';

const props = defineProps({
    link: {
        type: Object,
        required: true,
        default: () => {}
    }
});

/**
 * Given a variety of link types, return the URL to link to. Translate it, and replace any __home__ placeholders
*/
const url = computed(() => {
    if (!props.link) {
        return undefined;
    }

    let uri = null;
    if (props.link?.uri) {
        uri = props.link.uri;
    }

    else if (props.link?.url) {
        // URL is absolute, so return it unchanged
        return props.link.url;
    }

    if (uri === HOME_URI) {
        uri = '';
    }

    if (!uri) {
        return undefined;
    }

    // Strip any leading slashes
    uri = uri.replace(/^\/+/g, '');

    return `/${uri}`;
});
</script>
