import { computed, onMounted, ref } from 'vue';

const DESKTOP_MIN_WIDTH = (600 + (250 * 2));

export const useIsDesktop = () => {
    const isDesktop = ref(false);

    onMounted(() => {
        isDesktop.value = window.innerWidth >= DESKTOP_MIN_WIDTH;

        const handleResize = () => {
            isDesktop.value = window.innerWidth >= DESKTOP_MIN_WIDTH;
        };

        window.addEventListener('resize', handleResize);

        onUnmounted(() => {
            window.removeEventListener('resize', handleResize);
        });
    });

    return computed(() => isDesktop.value);
};

export default useIsDesktop;
