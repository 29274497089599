<template>
    <ClientOnly>
        <DnAccordion
            :id="slugify(title).toLowerCase()"
            ref="accordion"
            :items="[items]"
            :scroll-into-view="false"
            :scroll-smooth="true"
            class="base-accordion"
            :open-indexes="isDesktop ? [0] : []"
        >
            <template #title>
                <h4>
                    {{ title }}
                </h4>
            </template>


            <template #default>
                <FlexibleSections
                    :sections="Array.isArray(items) ? items : []"
                    :full-width="true"
                />
            </template>

            <template #icon>
                <BaseIcon
                    icon="chevron-down"
                    :style="{
                        opacity: isDesktop ? 0.5 : 1,
                    }"
                />
            </template>
        </DnAccordion>
    </ClientOnly>
</template>

<script setup lang="ts">
import DnAccordion from '@digitalnatives/accordion';
import BaseIcon from '~/components/BaseIcon/BaseIcon.vue';
import slugify from 'slugify';
import {useIsDesktop} from '~/composables/useIsDesktop';

const isDesktop = useIsDesktop();

type Props = {
    title: string,
    items: Array<any>,
};

defineProps<Props>();
</script>
