<template>
    <BaseSection
        v-if="block"
        section-width="content"
    >
        <div class="card-block">
            <img
                v-if="block.image && block.image.length"
                :src="block.image[0].url"
                alt=""
                class="card-block__img"
            >
            <div
                v-if="block.title || block.description"
                class="card-block__content"
            >
                <h4
                    v-if="block.title"
                    class="card-block__title"
                >
                    {{ block.title }}
                </h4>
                <!-- eslint-disable vue/no-v-html -->
                <div
                    v-if="block.description"
                    class="card-block__description"
                    v-html="block.description"
                />
            </div>
            <!-- eslint-enable vue/no-v-html -->
            <BaseInput
                v-if="block.blockActionType === 'inputField'"
                :placeholder="block.caption"
                class="card-block__action card-block__action--input"
            />

            <CraftLink
                v-else-if="block.blockActionType === 'button' && (block.internalLink?.[0] || block.externalLink)"
                :link="block.internalLink && block.internalLink.length ? block.internalLink[0] : block.externalLink"
                class="dn-button base-button card-block__action card-block__action--button"
            >
                {{ block.action }}
            </CraftLink>
            <p
                v-if="block.caption"
                class="card-block__caption"
            >
                {{ block.caption }}
            </p>
        </div>
    </BaseSection>
</template>

<script setup lang="ts">
type Props = {
    block: {
        image?: {
            url: string;
        }[];
        title?: string;
        description?: string;
        action?: string;
        blockActionType?: string;
        caption?: string;
        internalLink?: {
            uri: string;
        };
        externalLink?: string;
    }[]
}

const props = defineProps<Props>();

const block = computed(() => props.block?.[0]);
</script>

<style lang="less">
.card-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.44rem 1.5rem;
    position: relative;
    gap: 1.12rem;

    width: 100%;
    border-radius: 6px;

    & > * {
        text-align: center;
        z-index: 1;
    }

    p {
        width: 100%;
    }

    .dn-input {
        width: 100%;
        border-radius: var(--borderRadius-xl);
    }

    &:before {
        background: var(--color-alt-light, var(--color-primary-light));
        content: '';
        inset: 0;
        position: absolute;
        border-radius: var(--borderRadius-xs);
        z-index: 0;
        opacity: 1;
    }
}

.card-block__action--button {
    width: 100%;
    justify-content: center;
}

.card-block__title {
    margin: 0;
}

@media @q-lg-min {
    .card-block p,
    .card-block__description {
        max-width: 37.5rem;
        margin: 0 auto;
    }
}

.card-block__caption {
    margin-top: 0;
    font-size: 1rem;
    opacity: .4;
}
</style>
